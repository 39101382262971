// 300x250 (Medium rectangle)
// 728x90 (Leaderboard)
// 160x600 (Wide skyscraper)
// 300x600 (Half page)
// 320x50 (Mobile leaderboard)

export default {

  image: [
    {
      name: 'Rectangle',
      description: 'Image medium rectangle',
      type: 'image',
      form: 'banner_image',
      form_params: {
        custom_size: true,
        width:300,
        height:250,
      }
    },
    {
      name: 'Leaderboard',
      description: 'Image leaderboard',
      type: 'image',
      form: 'banner_image',
      form_params: {
        custom_size: true,
        width:728,
        height:90,
      }
    },
    {
      name: 'Skyscraper',
      description: 'Image skyscraper',
      type: 'image',
      form: 'banner_image',
      form_params: {
        custom_size: true,
        width:120,
        height:600,
      }
    },

    {
      name: 'Format: 300x250',
      description: '300x250 (Medium rectangle)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:300,
        height:250,
      }
    },

    {
      name: 'Format: 728x90',
      description: '728x90 (Leaderboard)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:728,
        height:90,
      }
    },

    {
      name: 'Format: 160x600',
      description: '160x600 (Wide skyscraper)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:160,
        height:600,
      }
    },

    {
      name: 'Format: 300x600',
      description: '300x600 (Half page)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:300,
        height:600,
      }
    },

    {
      name: 'Format: 320x50',
      description: '320x50 (Mobile leaderboard)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:300,
        height:50,
      }
    },

    // 300x50 (Mobile banner)
    // 320x480 (Portrait interstitial)
    // 480x320 (Landscape interstitial)
    // 120x600 (Skyscraper)


    {
      name: 'Format: 300x50',
      description: '300x50 (Mobile banner)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:300,
        height:50,
      }
    },
    {
      name: 'Format: 320x480',
      description: '320x480 (Portrait interstitial)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:320,
        height:480,
      }
    },
    {
      name: 'Format: 480x320',
      description: '480x320 (Landscape interstitial)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:480,
        height:320,
      }
    },
    {
      name: 'Format: 120x600',
      description: '120x600 (Skyscraper)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:120,
        height:600,
      }
    },

    // 468x60 (Full banner)
    // 336x280 (Large rectangle)
    // 250x250 (Square)
    // 320x100 (Mobile large banner)
    {
      name: 'Format: 468x60',
      description: '468x60 (Full banner)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:468,
        height:60,
      }
    },
    {
      name: 'Format: 336x280',
      description: '336x280 (Large rectangle)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:336,
        height:200,
      }
    },
    {
      name: 'Format: 250x250',
      description: '250x250 (Square)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:250,
        height:250,
      }
    },
    {
      name: 'Format: 320x100',
      description: '320x100 (Mobile large banner)',
      type: 'image',
      form: 'banner_image',
      form_params: {
        width:320,
        height:100,
      }
    },
  ],


  html: [
    {
      name: 'Rectangle',
      description: 'Html medium rectangle',
      type: 'html',
      form: 'banner_html',
      form_params: {
        custom_size: true,
        width:300,
        height:250,
      }
    },
    {
      name: 'Leaderboard',
      description: 'Html leaderboard',
      type: 'html',
      form: 'banner_html',
      form_params: {
        custom_size: true,
        width:728,
        height:90,
      }
    },
    {
      name: 'Skyscraper',
      description: 'Html skyscraper',
      type: 'html',
      form: 'banner_html',
      form_params: {
        custom_size: true,
        width:120,
        height:600,
      }
    },
  ],


  carousel: [
    {
      name: 'Square',
      description: 'Carousel with squared image',
      type: 'carousel',
      form: 'banner_carousel',
      form_params: {
        keep_size_ratio: true,
        width:1280,
        height:1280,
      }
    },

  ]

}

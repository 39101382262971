<template>
  <div class="banner-editor" v-if="values">

    <v-dialog v-model="dialog" persistent>

      <v-dialog v-model="dialog_erase" width="350px">
        <v-card class="pa-10">
          <p>{{$t('BANNER_EDITOR.CHANGE_WARNING')}}</p>
          <div class='d-flex'>
            <v-btn depressed small rounded @click="dialog_erase = false">{{$t('COMMON.BACK')}}</v-btn>
            <v-spacer />
            <v-btn depressed small rounded color="success" @click="confirmChangeType">{{$t('COMMON.CONTINUE')}}</v-btn>
          </div>
        </v-card>
      </v-dialog>

      <v-card>
        <v-system-bar dark color="primary">
          <small><v-icon class="mr-2" size="14">mdi-brush</v-icon><strong class="text-uppercase">{{ $t('BANNER_EDITOR.TITLE') }}</strong></small>
          <v-spacer/>
          <v-icon
            small
            class="clickable"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-system-bar>

        <v-row class="ma-0 height-100">

          <v-col cols="2" class="pa-0">
            <div class="height-80vh scroll primary">
              <v-divider dark />
              <v-list-item link dark dense>
                <v-list-item-content>
                  <v-list-item-title><strong>{{$t('BANNER_EDITOR.IMPORT_FROM_ZIP')}}</strong></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider dark />
              <div v-for="(key,i) in Object.keys(bannerInfo)" :key="'dit18'+i">
                <div class="pa-4"><v-icon color="grey" class="mr-2" size="16">mdi-image</v-icon><small class="grey--text text-capitalize">{{key}}</small></div>
                <v-divider dark />
                <v-list dense class="pa-0" color="primary" dark>
                  <v-list-item link v-for="(item,j) in bannerInfo[key]" :class="type_description === item.description ? 'accent' : ''" :key="'it156'+j" @click="changeTypeName(item)">
                    <v-list-item-content>
                      <v-list-item-title><strong>{{item.name}}</strong></v-list-item-title>
                      <v-list-item-subtitle><small>{{item.description}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-col>
          <v-divider vertical />
          <v-col cols="10" class="pa-0">
            <div class="height-80vh scroll d-flex flex-column" :key="renderKey">
              <v-toolbar dense flat color="transparent" height="90px">
                <v-toolbar-title>
                  <div><v-icon color="primary" class="mr-2" size="16">mdi-image</v-icon><small class="primary--text text-capitalize">{{type}}</small></div>
                  <div><strong class="mr-2">{{type_name}}</strong><small class="grey--text">{{type_description}}</small></div>
                </v-toolbar-title>
                <v-spacer />
                <v-btn
                  fab
                  small
                  depressed
                  color="success"
                  @click="save"
                  :disabled="!valid"
                >
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider />
              <v-form v-model="valid" class="d-flex height-100">
                <div class="flex-grow" v-if="type === 'html'">
                  <BannerEditorHtml :values="values" @input="handleChildData" />
                </div>
                <div class="flex-grow" v-if="type === 'image'">
                  <BannerEditorImage :values="values" @input="handleChildData" />
                </div>
                <div class="flex-grow" v-if="type === 'carousel'">
                  <BannerEditorCarousel :values="values" @input="handleChildData" />
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>



    <v-divider />
    <p class="primary--text text-capitalize"><small>{{ $t('CREATIVE.TEMPLATE') }}</small></p>
    <v-row class="mt-0" >
      <v-col cols="6" class="pb-0">

        <!-- <div class="text-ellipsis text-nowrap grey--text mt-4">
          {{ $t('CREATIVE.TEMPLATE') }}
        </div> -->

        <div>
          {{values.type}} <span v-if="values.width">{{values.content.width}}x{{values.content.height}}</span>
          <br /><small class="grey--text">{{type_description}}</small>
        </div>

        <div  class="mt-4">
          <v-btn small rounded depressed color="secondary black--text" @click="dialog = true">
            <v-icon>mdi-brush</v-icon>{{$t('COMMON.EDIT')}}
          </v-btn>
        </div>

      </v-col>
      <v-col cols="6" class="pb-0 overflow-hidden grey lighten-3" v-if="values && values.content && values.content.html">
        <BannerEditorPreview :values="values" />
      </v-col>
    </v-row>


    <template v-if="values && values.content">

      <v-divider class="mt-4 mb-4" />
      <p class="primary--text text-capitalize"><small>{{ $t('CREATIVE.CONTENT') }}</small></p>
      <v-row v-for="(ckey, i) in Object.keys(values.content)" :key="'co'+i" class="ma-0">
        <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
          {{ckey}}
        </v-col>
        <v-col cols="10" class="text-ellipsis text-nowrap">
          <div v-if="ckey === 'images'">
            <v-img class="d-inline-block ma-2" :title="img" height="20px" width="30px" v-for="(img, j) in values.content.images" :key="'im'+j" :src="values.content.images[j]" />
          </div>
          <span v-else>
            {{values.content[ckey]}}
          </span>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-4" />
      <p class="primary--text text-capitalize"><small>{{ $t('CREATIVE.PARAMS') }}</small></p>
      <v-row v-for="(ckey, i) in Object.keys(values.params)" :key="'pa'+i" class="ma-0">
        <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
          {{ckey}}
        </v-col>
        <v-col cols="10" class="text-ellipsis text-nowrap">
          <div v-if="ckey === 'images'">
            <v-img class="d-inline-block ma-2" :title="img" height="20px" width="30px" v-for="(img, j) in values.params.images" :key="'im'+j" :src="values.params.images[j]" />
          </div>
          <span v-else>
            {{values.params[ckey]}}
          </span>
        </v-col>
      </v-row>

    </template>

  </div>
</template>



<script>
import JSZip from 'jszip'
import BannerEditorImage from './BannerEditor.image'
import BannerEditorHtml from './BannerEditor.html'
import BannerEditorCarousel from './BannerEditor.carousel'
import BannerEditorPreview from './BannerEditor.preview'
import bannerInfo from './bannerInfo'
export default {
  name: "BannerEditor",
  components: {
    BannerEditorImage,
    BannerEditorHtml,
    BannerEditorCarousel,
    BannerEditorPreview
  },
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    dialog: false,
    dialog_erase: false,
    valid: false,
    renderKey:0,
    bannerInfo: bannerInfo,
    form_params: {},
    type: '',
    type_name: '',
    type_description: '',
    channelItems: [],
    channelSizeItems: [],
    content: {},
    params: {},
  }),
  created() {
    this.init()
  },

  watch: {
    // TO DO
    // IM HERE
    dialog (n) {
      if (n) {
        this.init()
      }
    },
    values: {
      handler () {
        this.init()
        // this.changeTypeName({
        //   type: n.type,
        //   width: n.width,
        //   height: n.height,
        //   type_name:n.content.type_name,
        // })
      },
      deep: true,
    }

  },


  beforeDestroy() {
    this.form_params = {}
    this.waiting_item = {}
    this.type = ''
    this.type_name = ''
    this.type_description = ''
    this.channelItems = []
    this.channelSizeItems = []
    this.content = {}
    this.params = {}
  },

  methods: {

    init () {
      /*
      content:
        $image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Tour_Eiffel_Wikimedia_Commons.jpg/260px-Tour_Eiffel_Wikimedia_Commons.jpg"
        $test: "HELLO !"
        html: "<div class=\"test-banner\"><h3>This is a dynamic macro:<br />$test</h3></div>"
        style: ".test-banner { display: flex; height:100%; width:100%; justify-content: center; align-items: center; background:url($image) center center; background-size:cover;}\n\n.test-banner h3 { display:inline-block; padding: 10px; background: blue; color: #fff; animation: zoom 2s infinite; }\n\n@keyframes zoom {\n0%{transform: scale(1) rotate(5deg);}\n50%{transform: scale(0.9) rotate(-5deg);}\n100%{transform: scale(1) rotate(5deg);}\n}"
        [[Prototype]]: Object
        office_id: "d7496260-8e0a-11eb-a696-6f801b865591"
        params: Object
        title: "azdazd"
        type: "html"
      */

      if (this.values && this.values.content && this.values.content.type) {
        const content = this.values.content
        this.changeTypeName({
          type: this.values.type,
          type_name:this.values.content.type_name,
          width: this.values.content.width || 200,
          height: this.values.content.height || 200,
        })
      } else {
        this.changeTypeName(this.bannerInfo['image'][0])
      }
    },


    loadZipFile (e) {
      // const handleFile = (file) => {
      //   JSZip.loadAsync(file) // read the Blob
      //     .then((zip) => {
      //       zip.forEach( (relativePath, zipEntry) => {  // files  entries
      //       })
      //     }, function (error) {
      //       throw error
      //     })
      // }
      //
      // var files = e.target.files;
      // for (var i = 0; i < files.length; i++) {
      //   handleFile(files[i]);
      // }
    },

    changeTypeName(item) {
      // if (this.content.html) {
      //   this.waiting_item = this.$clone(item)
      //   this.dialog_erase = true
      // } else {
        this.confirmChangeType(item, true)
      // }
    },

    confirmChangeType (item, force) {
      // item = force && item ? item : this.waiting_item
      this.dialog_erase = false
      this.waiting_item = {}
      // console.log('CHANGE', item)
      this.content = this.$clone(this.values.content)
      this.content.type = item.type,
      this.content.type_name = item.name,
      this.content.width = item.width || 200,
      this.content.height = item.height || 200
      this.params = {}
      //---
      this.type = item.type
      this.type_name = item.name
      this.type_description = item.description
      this.form_params = item.form_params
      this.renderKey++
    },

    handleChildData (data = {}) {
      // console.log('handleChildData 2 ', data)
      this.content = data.content || {}
      this.params = data.params || {}
    },

    save () {
      this.$emit('input', {
        type: this.type,
        content: this.$clone(this.content)
      })
      this.dialog = false
    }
  }

}

</script>
